/*-----------------------------------------------
|   Scrollbar style for windows
-----------------------------------------------*/

.scrollbar,
.scrollbar-overlay {
  @include chrome-scrollbar;
}
.navbar-vibrant {
  .scrollbar {
    @include chrome-scrollbar(rgba($white, 0.4));
  }
}

.firefox {
  .scrollbar {
    @include firefox-scrollbar;
  }
  .navbar-vibrant {
    .scrollbar {
      @include firefox-scrollbar(rgba($white, 0.4));
    }
  }
}

.os-theme-dark {
  & > .os-scrollbar {
    & > .os-scrollbar-track > .os-scrollbar-handle {
      background: $scrollbar-bg;
    }
    @include hover-focus {
      & > .os-scrollbar-track > .os-scrollbar-handle {
        background: $scrollbar-bg;
      }
    }
  }
}

/*-----------------------------------------------
|   Simplebar
-----------------------------------------------*/

.simplebar-track {
  &.simplebar-vertical {
    width: 10px;
  }
}
.simplebar-scrollbar {
  &::before {
    background: $scrollbar-bg;
  }
  &.simplebar-visible {
    &::before {
      opacity: 1;
    }
  }
}

.simplebar-content-wrapper {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}
