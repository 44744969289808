/*-----------------------------------------------
|   Search box
-----------------------------------------------*/
.search-box {
  position: relative;
  font-size: map-get($font-sizes, 9);
  // min-width: 15rem;
  width: 20rem;
  // @include media-breakpoint-up(lg) {
  //   width: 20rem;
  // }

  .search-box-icon {
    position: absolute;
    color: var(--#{$prefix}quaternary-color);
    top: 50%;
    left: 1rem;
    transform: translateY(-48%);
  }
  .search-input {
    padding-left: 2.5rem;
    padding-right: 2rem;
    border-radius: $border-radius;
    box-shadow: none;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  [data-bs-toggle='search']:not(.show) {
    + [data-bs-dismiss='search'] {
      display: none;
    }
  }

  .file-thumbnail {
    width: 1.75rem;
    height: 1.75rem;
  }
  &.ecommerce-search-box {
    width: 31.25rem;
    .search-input {
      border-radius: 3rem;
    }
  }
  &.navbar-top-search-box {
    .search-input {
      &:focus {
        border-color: var(--#{$prefix}border-color);
      }
    }
    .show .search-input {
      // border-bottom: 0;
      // border-bottom-left-radius: 0 !important;
      // border-bottom-right-radius: 0 !important;
      border-radius: 0.375rem 0.375rem 0 0 !important;
    }
    .dropdown-menu {
      margin-top: 0;
      &.show {
        border-top: 0 !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .dropdown-item {
        &:hover {
          background: var(--#{$prefix}body-highlight-bg);
        }
      }
    }
  }
}

// .safari {
//   .search-box .search-input {
//     line-height: 2;
//   }
// }
