/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */

@import '../../node_modules/bootstrap/scss/_functions';
@import 'theme/functions';

@import "user-variables";
@import 'theme/colors';
@import "theme/variables";
@import "theme/variables-dark";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

@import 'theme/maps';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../node_modules/bootstrap/scss/mixins';
@import 'theme/mixins';

@import 'theme/_utilities';
@import "../../node_modules/bootstrap/scss/utilities";

@import "../../node_modules/bootstrap/scss/root";
@import 'theme/root';

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import 'bootstrap';

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import 'theme/theme';
