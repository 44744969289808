/*-----------------------------------------------
|   Dropdown
-----------------------------------------------*/
.dropdown-menu {
  font-size: map_get($font-sizes, 9);
  // border-radius: $border-radius-lg;
}

.dropdown-caret-none {
  &:after,
  &:before {
    display: none !important;
  }
}

.dropdown-md {
  min-width: 16.625rem;
}

/*-----------------------------------------------
|   Theme control dropdown
-----------------------------------------------*/
.theme-control-dropdown {
  .dropdown-menu {
    --#{$prefix}dropdown-min-width: 8rem;
    // &.dropdown-menu-end {
    //   right: 1px !important;
    // }
    .dropdown-item {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
      &.active {
        color: var(--#{$prefix}body-color);
        background-color: var(--#{$prefix}body-highlight-bg);
      }
      &:not(.active) {
        .dropdown-check-icon {
          display: none;
        }
      }
    }
  }
}