/* -------------------------------------------------------------------------- */
/*                             Bootstrap Carousel                             */
/* -------------------------------------------------------------------------- */

.theme-slider {
  @include hover-focus {
    .carousel-control-next,
    .carousel-control-prev {
      color: var(--#{$prefix}tertiary-color);
      opacity: 0.5 !important;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 3rem;
    height: 3rem;
    background: var(--#{$prefix}emphasis-bg);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 4px 80px 84px rgba(83, 83, 83, 0.02);
    transition: opacity 0.4s ease-in-out;
    color: var(--#{$prefix}tertiary-color);
    opacity: 0;
    @include hover-focus {
      opacity: 1 !important;
    }
  }
  .carousel-control-prev {
    left: map_get($spacers, 2);
  }
  .carousel-control-next {
    right: map_get($spacers, 2);
  }
}

.testimonial-carousel{
  box-shadow: 0px 4px 80px 84px rgba(83, 83, 83, 0.02);
  border-radius: 1.5rem;
  .carousel-indicators{
    bottom: -3.75rem;
    button{
      width: 12px;
      height: 3px;
      border-radius: 0.5rem;
      border: 0;
      transition: width 0.5s;
      &.active{
        width: 1.5rem;
      }
    }
  }
}