/* -------------------------------------------------------------------------- */
/*                               Settings Panel                               */
/* -------------------------------------------------------------------------- */
.settings-panel {
  .img-prototype {
    &[data-bs-theme='dark'] {
      display: none;
    }
    [dir='rtl'] & {
      transform: scaleX(-1);
    }
  }
}
[data-bs-theme='dark'] {
  .settings-panel {
    .img-prototype {
      [data-bs-theme='light'] {
        display: none;
      }
      &[data-bs-theme='dark'] {
        display: block;
      }
    }
  }
}

.settings-panel {
  max-width: 28.62rem;
  width: 100% !important;
}
.setting-panel-item {
  border-radius: $border-radius-lg;
  background-color: var(--#{$prefix}body-bg);
  margin-top: 1rem;
  border: 1px solid var(--#{$prefix}border-color-translucent);
  padding: 1rem 1rem 0 1rem;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 1.5rem 0.25rem 1.5rem;
  }
}
.setting-panel-item-title {
  line-height: $line-height-sm;
  color: var(--#{$prefix}body-color);
  margin-bottom: 0.5rem;
}

.settings-panel-header {
  align-items: flex-start;
  padding: 0.5rem 1.25rem;
  &:before,
  &:after {
    border-radius: 50%;
    height: 12.5rem;
    width: 12.5rem;
  }
  &:before {
    left: 5.125rem;
    top: 1.188rem;
  }
  &:after {
    right: -6.25rem;
    top: -7.938rem;
  }
}

.setting-panel-item {
  .btn-check {
    + .btn-navbar-style {
      box-shadow: none;
      padding: 0;
      margin-bottom: 1rem;
      text-align: left;
      .img-prototype {
        border: 2px solid var(--#{$prefix}border-color-translucent);
        border-radius: $border-radius-lg;
        transition: border 0.2s ease;
        margin-bottom: 0.5rem;
        background-color: var(--#{$prefix}body-highlight-bg);
      }
      .label-text {
        position: relative;
        padding-left: 1.5rem;
        &:after {
          position: absolute;
          content: '';
          left: 0;
          width: 1rem;
          height: 1rem;
          border: 1px solid var(--#{$prefix}border-color);
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          transition: border 0.2s ease;
        }
      }
    }
    &:checked:not([disabled]) + .btn-navbar-style {
      .img-prototype {
        border-color: var(--#{$prefix}primary);
      }
      .label-text::after {
        border: 5px solid var(--#{$prefix}primary);
      }
    }
    &:disabled {
      + .btn-navbar-style {
        opacity: 0.5;
      }
    }
  }
  .hover-overlay {
    position: relative;
    overflow: hidden;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      z-index: 1;
      transition: $transition-base;
    }
    @include hover-focus {
      &:after {
        background: rgba($black, 0.2);
      }
    }
  }
}

.setting-toggle {
  position: fixed;
  top: 50%;
  right: -3px;
  text-decoration: none;
  z-index: 1016;
  transform: rotate(-90deg) translate3d(-25px, 39px, 0);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: -83px;
  box-shadow: var(--#{$prefix}setting-toggle-shadow);

  @include media-breakpoint-down(sm) {
    transform: rotate(-90deg);
    outline: none;
    small {
      display: none;
    }
  }

  &:hover {
    text-decoration: none !important;
  }
}
