.icon-indicator {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    right: -3px;
    top: 1px;
    height: 19px;
    width: 19px;
    border-radius: 50%;
  }
  &-sm {
    &::before {
      height: 0.5rem;
      width: 0.5rem;
      right: 9px;
      top: 8px;
    }
  }
  &-number {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -1.5px;
    top: 3.5px;
    height: 1rem;
    width: 1rem;
    font-size: map-get($font-sizes, 9);
    color: $white;
    font-weight: $font-weight-bold;
  }
  & {
    @each $state, $value in $theme-colors {
      &-#{$state}::before {
        background-color: $value;
      }
    }
  }
  & {
    &-fill::before {
      border: 0;
    }
  }
}
