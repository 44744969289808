//
// Headings
//
h1,
.h1 {
  font-weight: $font-weight-bolder;
}

h2,
.h2 {
  font-weight: $font-weight-bolder;
}

h3,
.h3 {
  font-weight: $font-weight-bolder;
}
.lead {
  line-height: $line-height-lg;
}

/*-----------------------------------------------
|   List
-----------------------------------------------*/
.bullet-inside {
  list-style-position: inside;
}

/*-----------------------------------------------
|   Line Clamp
-----------------------------------------------*/

@for $i from 1 through 3 {
  .line-clamp-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@each $key, $value in $theme-colors-bg-subtle{
  .text-#{$key}-subtle{
    color: var(--#{$prefix}#{$key}-bg-subtle) !important;
  }
}
[data-bs-theme="dark"]{
  @each $key, $value in $theme-colors{
    .text-bg-#{$key}{
      @if $key == 'secondary' {
        color: $gray-100 !important;
      } @else{
        color: var(--#{$prefix}black) !important;
      }
    }
  }
}
